import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

import AttachmentActionMenu from './AttachmentActionMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faFilePdf,
  faFileZipper,
  faImage
} from '@fortawesome/pro-duotone-svg-icons';

const AttachmentIcon = ({ filename }) => {
  const imageColor = useColorModeValue('blue.500', 'blue.200');
  const pdfColor = useColorModeValue('red.500', 'red.200');
  const zipColor = useColorModeValue('purple.500', 'purple.200');
  const fileColor = useColorModeValue('teal.500', 'teal.200');
  // Use image icon
  if (filename.toLowerCase().match(/[.](gif|jpg|jpeg|png|tiff|webp)$/i)) {
    return (
      <Text fontSize="2xl" color={imageColor}>
        <FontAwesomeIcon icon={faImage} />
      </Text>
    );
    // Use pdf icon
  } else if (filename.toLowerCase().match(/[.]pdf$/i)) {
    return (
      <Text fontSize="2xl" color={pdfColor}>
        <FontAwesomeIcon icon={faFilePdf} />
      </Text>
    );
  } else if (filename.toLowerCase().match(/[.](rar|zip)$/i)) {
    return (
      <Text fontSize="2xl" color={zipColor}>
        <FontAwesomeIcon icon={faFileZipper} />
      </Text>
    );
  }
  // Use file icon
  return (
    <Text fontSize="2xl" color={fileColor}>
      <FontAwesomeIcon icon={faFileAlt} />
    </Text>
  );
};

const AttachmentListItem = ({
  attachment,
  onClickDelete,
  onClickUpdate,
  onClickSetAsThumbnail,
  canEdit
}) => {
  const downloadAttachment = async () => {
    try {
      const response = await fetch(attachment.file);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', attachment.name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Failed to download file', error);
    }
  };

  return (
    <>
      <Flex
        position="relative"
        height="120px"
        borderWidth={1}
        alignItems="space-between"
        rounded="lg"
        cursor="pointer"
        onClick={downloadAttachment}
      >
        <Box position="absolute" right={2} top={2}>
          {canEdit && (
            <AttachmentActionMenu
              onClickDelete={() => onClickDelete(attachment)}
              onClickUpdate={() => onClickUpdate(attachment)}
              onClickSetImage={null}
            />
          )}
        </Box>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          padding={6}
        >
          <AttachmentIcon filename={attachment.name} />
          <Text>{attachment.name}</Text>
        </Flex>
      </Flex>
    </>
  );
};

export default AttachmentListItem;
