import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  Skeleton,
  Stack,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import CarouselHeader from 'components/CarouselHeader';
import CarouselControls from 'components/CarouselControls';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import { isCollectionManager } from 'features/collection/collectionUtils';
import { useAuth } from 'providers/AuthProvider';
import { useCard } from 'providers/CardProvider';
import { useUi } from 'providers/UiProvider';
import api from 'utils/api';
import { cardFilterToArray, isAssignee, isContributor } from './cardUtils';
import ChangeCollectionModal from './ChangeCollectionModal';
import CardBody from './CardBody';
import CardForm from './CardForm';
import CardHeader from './CardHeader';
import CardReport from './CardReport';

const transition = { duration: 0.25, ease: 'linear' };

const variants = {
  start: {
    scale: 0.7,
    rotateY: 90,
    transition: transition
  },
  final: { scale: 1, rotateY: 0, transition: transition }
};

const CardDetailed = ({ code, cardId, isOpen, onClose }) => {
  const [variant, setVariant] = useState(null);
  const { authenticated } = useAuth();
  const { carouselCardIds } = useCard();
  const { collectionRefetch, filter } = useUi();

  const {
    isOpen: showReport,
    onClose: closeReport,
    onToggle: toggleReport
  } = useDisclosure();

  const {
    isOpen: showDeleteCardModal,
    onOpen: openDeleteCardModal,
    onClose: closeDeleteCardModal
  } = useDisclosure();

  const {
    isOpen: showUpdateCardModal,
    onOpen: openUpdateCardModal,
    onClose: closeUpdateCardModal
  } = useDisclosure();

  const {
    isOpen: showChangeCollectionModal,
    onOpen: openChangeCollectionModal,
    onClose: closeChangeCollectionModal
  } = useDisclosure();

  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug], { enabled: false });
  const { data: me } = useQuery('me', { enabled: false });
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  const {
    data: card,
    status,
    isSuccess,
    refetch: fetchCard
  } = useQuery(
    ['card', cardId],
    async () => {
      const { data } = await api.get(
        code
          ? `/cards/${cardId}?code=${code}&hive=${slug}`
          : `/cards/${cardId}?hive=${slug}`
      );
      return data;
    },
    {
      enabled: false,
      onSuccess: async () => {
        if (carouselCardIds.length > 1) {
          const startIndex = carouselCardIds.findIndex(card => card === cardId);
          if (startIndex > -1) {
            const nextCardIndex =
              startIndex < carouselCardIds.length - 1 ? startIndex + 1 : 0;
            const nextCardId = carouselCardIds[nextCardIndex];
            const { data: nextCard } = await api.get(
              code
                ? `/cards/${nextCardId}?code=${code}&hive=${slug}`
                : `/cards/${nextCardId}?hive=${slug}`
            );
            queryClient.setQueryData(['card', nextCardId], nextCard);
            const previousCardIndex =
              startIndex > 0 ? startIndex - 1 : carouselCardIds.length - 1;
            const previousCardId = carouselCardIds[previousCardIndex];
            const { data: previousCard } = await api.get(
              code
                ? `/cards/${previousCardId}?code=${code}&hive=${slug}`
                : `/cards/${previousCardId}?hive=${slug}`
            );
            queryClient.setQueryData(['card', previousCardId], previousCard);
          }
        }
      }
    }
  );

  useEffect(() => {
    if (isOpen && cardId) {
      fetchCard();
    }
  }, [fetchCard, isOpen, cardId]);

  useEffect(() => {
    closeReport();
  }, [isOpen, closeReport]);

  const updateCard = card => {
    queryClient.setQueryData(['card', cardId], card);
    const cards = queryClient.getQueryData([
      'cards',
      { hive: slug },
      ...cardFilterToArray({ ...filter })
    ]);
    if (cards) {
      const pages = cards.pages.map(page => ({
        ...page,
        results: page.results.map(c => (c.id === card.id ? card : c))
      }));
      queryClient.setQueryData(
        ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
        {
          ...cards,
          pages
        }
      );
    }
  };

  const deleteCardMutation = useMutation(
    card =>
      api.delete(
        code
          ? `/cards/${cardId}?code=${code}&hive=${slug}`
          : `/cards/${cardId}?hive=${slug}`,
        card
      ),
    {
      onSuccess: () => {
        const cards = queryClient.getQueryData([
          'cards',
          { hive: slug },
          ...cardFilterToArray({ ...filter })
        ]);
        if (cards) {
          const pages = cards.pages.map(page => ({
            ...page,
            count: page.count - 1,
            results: page.results.filter(c => c.id !== card.id)
          }));
          queryClient.setQueryData(
            ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
            {
              ...cards,
              pages
            }
          );
        }
        closeDeleteCardModal();
        onClose();
        toast({
          title: 'Card was successfully deleted.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const updateCardMutation = useMutation(
    card =>
      api.patch(
        code
          ? `/cards/${cardId}?code=${code}&hive=${slug}`
          : `/cards/${cardId}?hive=${slug}`,
        card
      ),
    {
      onSuccess: ({ data: card }) => {
        updateCard(card);
      }
    }
  );

  const createVoteMutation = useMutation(
    payload =>
      api.post(
        code
          ? `/cards/${cardId}/vote?code=${code}&hive=${slug}`
          : `/cards/${cardId}/vote?hive=${slug}`,
        payload
      ),
    {
      onSuccess: ({ data: card }) => {
        updateCard(card);
      }
    }
  );

  const deleteVoteMutation = useMutation(
    payload =>
      api.delete(
        code
          ? `/cards/${cardId}/vote?code=${code}&hive=${slug}`
          : `/cards/${cardId}/vote?hive=${slug}`,
        payload
      ),
    {
      onSuccess: ({ data: card }) => {
        updateCard(card);
      }
    }
  );

  const changeCollectionMutation = useMutation(
    payload => api.post(`/cards/${cardId}/transfer?hive=${slug}`, payload),
    {
      onSuccess: ({ data: card }) => {
        closeChangeCollectionModal();
        onClose();
        if (collectionRefetch) {
          collectionRefetch();
        }
        const cards = queryClient.getQueryData([
          'cards',
          { hive: slug },
          ...cardFilterToArray({ ...filter })
        ]);
        if (card) {
          const pages = cards.pages.map(page => ({
            ...page,
            count: page.count - 1,
            results: page.results.filter(c => c.id !== card.id)
          }));
          queryClient.setQueryData(
            ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
            {
              ...cards,
              pages
            }
          );
        }
        // TODO: remove card from current collection and decrement counter for appropriate state
        toast({
          title: 'Card was successfully moved.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const handleOnChangeCollection = async ({ collection }) => {
    if (card.collection.id !== collection.id) {
      await changeCollectionMutation.mutateAsync({
        collection: collection.id,
        hive: slug
      });
    } else {
      closeChangeCollectionModal();
    }
  };

  const handleOnUpdateCard = async card => {
    const payload = new FormData();
    if (!card.image || card.image instanceof File) {
      payload.append('image', card.image ? card.image : '');
    }
    payload.append('unsplash', card.unsplash ? card.unsplash : '');
    payload.append('title', card.title);
    payload.append('description', card.description);
    payload.append('hive', slug);
    try {
      await updateCardMutation.mutateAsync(payload);
      closeUpdateCardModal();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnClickVote = async vote => {
    if (me) {
      if (vote === card.voted) {
        await deleteVoteMutation.mutateAsync({ hive: slug });
      } else {
        await createVoteMutation.mutateAsync({ type: vote, hive: slug });
      }
    }
  };

  const handleOnClickDelete = async () => {
    await deleteCardMutation.mutateAsync({
      hive: slug
    });
  };

  const CardBodySkeleton = () => (
    <Stack spacing={8}>
      <Skeleton height="25vh" width="100%" rounded="lg" />
      <Skeleton height="10vh" width="100%" rounded="lg" />
      <Skeleton height="20vh" width="100%" rounded="lg" />
      <Skeleton height="25vh" width="100%" rounded="lg" />
    </Stack>
  );

  const canEdit =
    card && me
      ? profile?.is_admin ||
        isAssignee(card, me) ||
        isContributor(card, me) ||
        isCollectionManager(card.collection, me)
      : false;

  const isAdminOrCollectionManager =
    profile?.is_admin || isCollectionManager(card?.collection, me);

  const isAdminOrAssigneeOrCollectionManager =
    isAssignee(card, me) || isAdminOrCollectionManager;

  const handleOnChangeContributors = async contributors => {
    try {
      await updateCardMutation.mutateAsync({
        contributors: contributors.map(contributor => contributor.id),
        hive: slug
      });
    } catch (e) {
      console.log(e);
    }
  };

  const flipCard = () => {
    setVariant('start');
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={['full', null, '3xl']}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        position="relative"
        as={motion.div}
        style={{ originY: 0 }}
        variants={variants}
        animate={variant ? variant : {}}
        onAnimationComplete={animation => {
          if (animation === 'start') {
            setVariant('final');
            toggleReport();
          } else {
            setVariant(null);
          }
        }}
      >
        {carouselCardIds.length > 0 && (
          <Show above="xl">
            <CarouselHeader card={card} />
            <CarouselControls />
          </Show>
        )}
        <ModalHeader mr={12}>
          {isSuccess && !showReport && (
            <CardHeader
              canEdit={canEdit}
              card={card}
              code={code}
              collectionRefetch={collectionRefetch}
              isAdminOrCollectionManager={isAdminOrCollectionManager}
              isAdminOrAssigneeOrCollectionManager={
                isAdminOrAssigneeOrCollectionManager
              }
              updateCardMutation={updateCardMutation}
            />
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LoadingWrapper
            statuses={[status]}
            errorMessages={[
              t('common.could_not_fetch_data_please_try_again_later', {
                data: t('common.card').toLowerCase()
              })
            ]}
            indicator={<CardBodySkeleton />}
          >
            {isSuccess && (
              <>
                {showReport ? (
                  <CardReport card={card} onClose={flipCard} />
                ) : (
                  <>
                    <CardBody
                      authenticated={authenticated}
                      code={code}
                      card={card}
                      onClickVote={handleOnClickVote}
                      updateCard={updateCard}
                      canEdit={canEdit}
                      isAdminOrCollectionManager={isAdminOrCollectionManager}
                      isAdminOrAssigneeOrCollectionManager={
                        isAdminOrAssigneeOrCollectionManager
                      }
                      onChangeContributors={handleOnChangeContributors}
                      onClickDelete={openDeleteCardModal}
                      onClickEdit={openUpdateCardModal}
                      onClickViewReport={flipCard}
                      onClickChangeCollection={openChangeCollectionModal}
                      updateCardMutation={updateCardMutation}
                    />
                    <ModalWrapper
                      title={card.title}
                      isOpen={showUpdateCardModal}
                      onClose={closeUpdateCardModal}
                    >
                      <CardForm
                        collection={card.collection}
                        titleOverride={card.collection.workflow?.title_override}
                        useDescription={
                          card.collection.workflow?.use_card_description
                        }
                        useImage={card.collection.workflow?.use_card_image}
                        workflowId={card.collection.workflow?.id}
                        defaultValues={{
                          ...card,
                          description: card.description
                        }}
                        isOpen={showUpdateCardModal}
                        onSubmit={handleOnUpdateCard}
                      />
                    </ModalWrapper>
                    <ConfirmationModal
                      deleteText={t(
                        'confirmation.all_information_in_this_card_will_be_permanently_removed_and_cannot_be_restored'
                      )}
                      isOpen={showDeleteCardModal}
                      onClose={closeDeleteCardModal}
                      onDelete={handleOnClickDelete}
                    />
                    {authenticated && (
                      <ChangeCollectionModal
                        collection={card.collection}
                        isOpen={showChangeCollectionModal}
                        onClose={closeChangeCollectionModal}
                        onSubmit={handleOnChangeCollection}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </LoadingWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CardDetailed;
