import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';

const MoveToProjectModal = ({ folder, isOpen, onClose, onChange }) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    status,
    data: folders,
    isSuccess,
    refetch
  } = useQuery(
    ['folders'],
    async () => {
      const { data } = await api.get(`/folders?hive__slug=${slug}`, {
        timeout: 30000
      });
      return data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  return (
    <ModalWrapper
      title={t('common.folder')}
      isOpen={isOpen}
      onClose={onClose}
      pb={8}
    >
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.folders').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <Select
            defaultValue={folder}
            options={folders}
            placeholder={t('placeholder.search')}
            getOptionLabel={folder => folder.name}
            getOptionValue={folder => folder.id}
            onChange={onChange}
            isClearable
            useBasicStyles
          />
        )}
      </LoadingWrapper>
    </ModalWrapper>
  );
};

export default MoveToProjectModal;
