import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  Button,
  Container,
  Flex,
  SimpleGrid,
  useColorModeValue,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingWrapper from 'components/LoadingWrapper';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import MembersModal from 'components/modals/MembersModal';
import ModalWrapper from 'components/ModalWrapper';
import { useUi } from 'providers/UiProvider';
import api from 'utils/api';
import CollectionPage from './CollectionPage';
import CollectionForm from './CollectionForm';
import CollectionSidebar from './CollectionSidebar';
import ChangeWorkflowForm from './ChangeWorkflowForm';
import MoveToFolderModal from './MoveToFolderModal';
import ExportModal from './ExportModal';
import PublishCollectionModal from './PublishCollectionModal';
import ShareCollection from './ShareCollection';
import { isCollectionManager } from './collectionUtils';
import CollectionDashboard from './CollectionDashboard';
import StepDetailed from 'features/step/StepDetailed';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { cardFilterToArray } from 'features/card/cardUtils';

const CollectionDetailed = () => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const { data: profile } = useQuery(['profile', slug]);

  const {
    setCollectionRefetch,
    setShowMobileCreateButton,
    scrollRef,
    filter,
    setFilter
  } = useUi();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedView, setSelectedView] = useState(
    searchParams.get('step') ? 'step' : 'collection'
  );

  const { update } = useIntercom();
  const { t } = useTranslation();

  const {
    isOpen: showMoveToFolderModal,
    onOpen: openMoveToFolderModal,
    onClose: closeMoveToFolderModal
  } = useDisclosure();

  const {
    isOpen: showDeleteCollectionModal,
    onOpen: openDeleteCollectionModal,
    onClose: closeDeleteCollectionModal
  } = useDisclosure();

  const {
    isOpen: showExportModal,
    onOpen: openExportModal,
    onClose: closeExportModal
  } = useDisclosure();

  const { isOpen: showSidebar, onToggle: toggleSidebar } = useDisclosure({
    defaultIsOpen: window.innerWidth >= 1024
  });

  const { collectionId } = useParams();

  const { data: me } = useQuery('me');
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    return () => {
      setCollectionRefetch(null);
      setShowMobileCreateButton(true);
    };
  }, [setCollectionRefetch, setShowMobileCreateButton]);

  useEffect(() => {
    if (Object.keys(filter).length < 1) {
      setFilter({
        ...filter,
        collection: collectionId,
        step: searchParams.get('step')
          ? parseInt(searchParams.get('step'))
          : undefined
      });
    }
  }, [filter, setFilter, searchParams, collectionId]);

  const { data: invites, refetch: inviteRefetch } = useQuery(
    ['invites', collectionId],
    async () => {
      const { data: invites } = await api.get(
        `/collections/invites?collection=${collectionId}&hive__slug=${slug}`
      );
      return invites;
    }
  );

  const deleteCollectionMutation = useMutation(
    () => api.delete(`/collections/${collectionId}?hive__slug=${slug}`),
    {
      onSuccess: () => {
        const hive = queryClient.getQueryData(['hive', collection.hive.slug]);
        queryClient.setQueryData(['hive', collection.hive.slug], {
          ...hive,
          metrics: {
            ...hive.metrics,
            collections: hive.metrics.collections - 1
          }
        });
        closeDeleteCollectionModal();
        toast({
          title: 'Collection was successfully deleted.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        navigate(`/${slug}`);
      }
    }
  );

  const updateCollectionMutation = useMutation(
    collection =>
      api.patch(`/collections/${collectionId}?hive__slug=${slug}`, collection, {
        timeout: 0
      }),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(
          ['collection', collectionId, { hive__slug: slug }],
          data
        );
        toast({
          title: 'Collection was successfully updated.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const {
    status,
    data: collection,
    isSuccess,
    refetch
  } = useQuery(
    ['collection', collectionId, { hive__slug: slug }],
    async () => {
      const { data } = await api.get(
        `/collections/${collectionId}?hive__slug=${slug}`
      );
      return data;
    },
    {
      onSuccess: collection => {
        setShowMobileCreateButton(collection.open);
        setCollectionRefetch(() => refetch);
        const collectionManager = isCollectionManager(collection, me);
        update({
          customAttributes: {
            collection_manager: collectionManager
          }
        });
      },
      onError: () => {
        update({
          customAttributes: {
            collection_manager: false
          }
        });
      }
    }
  );

  const refreshCards = (card, step) => {
    refetch();
    const isAll = filter.step === null;
    if (!isAll) {
      let shouldRemoveCard = false;
      if (card?.step?.id !== step) {
        shouldRemoveCard = true;
      }
      const cards = queryClient.getQueryData([
        'cards',
        { hive: slug },
        ...cardFilterToArray({ ...filter })
      ]);
      if (card) {
        if (shouldRemoveCard) {
          const pages = cards.pages.map(page => ({
            ...page,
            count: page.count - 1,
            results: page.results.filter(c => c.id !== card.id)
          }));
          queryClient.setQueryData(
            ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
            {
              ...cards,
              pages
            }
          );
        } else {
          const pages = cards.pages.map(page => {
            return {
              ...page,
              count: page.count + 1
            };
          });
          if (pages.length > 0) {
            pages[0].results = [card, ...pages[0].results];
          }
          queryClient.setQueryData(
            ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
            {
              ...cards,
              pages
            }
          );
        }
      }
    }
  };

  // TODO: code is hard to follow and should be refactored
  const changeCardStep = async (card, { step }) => {
    try {
      const { data } = await api.patch(`/cards/${card.id}?hive=${slug}`, {
        step
      });
      refreshCards(card, step);
      const cards = queryClient.getQueryData([
        'cards',
        { hive: slug },
        ...cardFilterToArray({ ...filter })
      ]);
      if (cards) {
        const pages = cards.pages.map(page => ({
          ...page,
          results: page.results.map(c => (c.id === data.id ? data : c))
        }));
        queryClient.setQueryData(
          ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
          {
            ...cards,
            pages
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const {
    isOpen: showEditCollectionModal,
    onOpen: openEditCollectionModal,
    onClose: closeEditCollectionModal
  } = useDisclosure();

  const {
    isOpen: showPublishCollectionModal,
    onOpen: openPublishCollectionModal,
    onClose: closePublishCollectionModal
  } = useDisclosure();

  const {
    isOpen: showShareCollectionModal,
    onOpen: openShareCollectionModal,
    onClose: closeShareCollectionModal
  } = useDisclosure();

  const {
    isOpen: showCollectionManagersModal,
    onOpen: openCollectionManagersModal,
    onClose: closeCollectionManagersModal
  } = useDisclosure();

  const {
    isOpen: showCollectionWorkflowModal,
    onOpen: openCollectionWorkflowModal,
    onClose: closeCollectionWorkflowModal
  } = useDisclosure();

  const handleOnLinkCreated = data => {
    queryClient.setQueryData(
      ['collection', collectionId, { hive__slug: slug }],
      data
    );
  };

  const handleOnLinkDeleted = data => {
    queryClient.setQueryData(
      ['collection', collectionId, { hive__slug: slug }],
      data
    );
  };

  const handleOnSocialMediaChange = data => {
    queryClient.setQueryData(
      ['collection', collectionId, { hive__slug: slug }],
      data
    );
  };

  const handleOnChangeBoard = async board => {
    await updateCollectionMutation.mutateAsync({
      board,
      hive__slug: slug
    });
  };

  const handleOnChangeManagers = async members => {
    const managers = members.map(member => member.id);
    await updateCollectionMutation.mutateAsync({
      managers,
      hive__slug: slug
    });
  };

  const handleOnChangeFolder = async folder => {
    await updateCollectionMutation.mutateAsync({
      folder: folder ? folder.id : null,
      hive__slug: slug
    });
  };

  const handleOnDeleteCollection = async () => {
    await deleteCollectionMutation.mutateAsync();
  };

  const handleOnUpdateCollection = async collection => {
    const payload = new FormData();
    if (!collection.image || collection.image instanceof File) {
      payload.append('image', collection.image ? collection.image : '');
    }
    if (!collection.video) {
      payload.append('video', '');
    }
    payload.append('unsplash', collection.unsplash ? collection.unsplash : '');
    payload.append(
      'workflow',
      collection.workflow ? collection.workflow.id : ''
    );
    payload.append('title', collection.title);
    payload.append('description', collection.description);
    payload.append('hide_comments', collection.hide_comments);
    payload.append('hive__slug', slug);
    try {
      if (collection.video instanceof File) {
        const { data } = await api.post('/s3/generate-presigned-url', {
          filename: collection.video.name
        });
        await axios.put(data.url, collection.video, {
          headers: { 'x-amz-acl': 'public-read' }
        });
        payload.append('video', data.key);
      }
    } catch (e) {
      console.log(e);
    }
    await updateCollectionMutation.mutateAsync(payload);
  };

  const handleOnClickCard = card => {
    if (card.id) {
      setSearchParams({ card: card.id });
    }
  };

  const handleOnClickOpen = async () => {
    await updateCollectionMutation.mutateAsync({
      open: !collection.open,
      hive__slug: slug
    });
  };

  const isAdminOrCollectionManager =
    profile?.is_admin || isCollectionManager(collection, me);

  const sidebarButtonBackground = useColorModeValue('white', 'gray.800');

  return (
    <>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.collection').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <SimpleGrid
            templateColumns={
              isAdminOrCollectionManager && showSidebar
                ? ['300px 1fr']
                : ['1fr']
            }
          >
            {isAdminOrCollectionManager && showSidebar && (
              <Flex
                flexDir="column"
                overflowY="auto"
                height="calc(100vh - 70px)"
                padding={0}
                borderRightWidth={1}
              >
                <CollectionSidebar
                  collection={collection}
                  invites={invites}
                  filter={filter}
                  selectedView={selectedView}
                  setSelectedView={setSelectedView}
                  setFilter={setFilter}
                  isOpen={showSidebar}
                  changeCardStep={changeCardStep}
                  onToggleSidebar={toggleSidebar}
                  onClickOpen={handleOnClickOpen}
                  onClickStepEdit={openCollectionWorkflowModal}
                />
              </Flex>
            )}
            <Flex
              ref={scrollRef}
              overflowY="scroll"
              height="calc(100vh - 70px)"
            >
              <Container maxW="container.lg" marginY={8}>
                <>
                  {selectedView === 'collection' ? (
                    <CollectionPage
                      onClickMoveToFolder={openMoveToFolderModal}
                      onClickDelete={openDeleteCollectionModal}
                      onClickEdit={openEditCollectionModal}
                      onClickExport={openExportModal}
                      onClickManagers={openCollectionManagersModal}
                      onClickPublish={openPublishCollectionModal}
                      onClickShare={openShareCollectionModal}
                      isAdminOrCollectionManager={isAdminOrCollectionManager}
                      collectionId={collectionId}
                      collection={collection}
                    />
                  ) : selectedView === 'step' ? (
                    <StepDetailed
                      collection={collection}
                      filter={filter}
                      onClickShare={openShareCollectionModal}
                      onClickCard={handleOnClickCard}
                      setFilter={setFilter}
                    />
                  ) : selectedView === 'dashboard' ? (
                    <CollectionDashboard collection={collection} />
                  ) : null}
                  {isAdminOrCollectionManager && (
                    <>
                      {!showSidebar && (
                        <Button
                          position="fixed"
                          left={0}
                          top={40}
                          onClick={toggleSidebar}
                          borderLeftRadius={0}
                          bg={sidebarButtonBackground}
                          borderWidth={1}
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                      )}

                      <ModalWrapper
                        title={t('common.collection')}
                        isOpen={showEditCollectionModal}
                        onClose={closeEditCollectionModal}
                      >
                        <CollectionForm
                          defaultValues={{
                            ...collection,
                            description: collection.description
                          }}
                          isOpen={showEditCollectionModal}
                          onSubmit={async data => {
                            await handleOnUpdateCollection(data);
                            closeEditCollectionModal();
                          }}
                        />
                      </ModalWrapper>
                      {hive.use_share && (
                        <ModalWrapper
                          title={t('common.share_collection')}
                          isOpen={showShareCollectionModal}
                          onClose={closeShareCollectionModal}
                          size="xl"
                        >
                          <ShareCollection
                            collection={collection}
                            onLinkCreated={handleOnLinkCreated}
                            onLinkDeleted={handleOnLinkDeleted}
                            onInviteChange={inviteRefetch}
                            onShareSocialMediaChange={handleOnSocialMediaChange}
                          />
                        </ModalWrapper>
                      )}
                      <MembersModal
                        code={collection.code}
                        members={collection.managers}
                        title={t('common.managers')}
                        body={t('collection.managers_explainer')}
                        isOpen={showCollectionManagersModal}
                        onClose={closeCollectionManagersModal}
                        onChangeMembers={handleOnChangeManagers}
                        canEdit
                      />
                      <PublishCollectionModal
                        board={collection.board}
                        isOpen={showPublishCollectionModal}
                        onClose={closePublishCollectionModal}
                        onChange={handleOnChangeBoard}
                      />
                      <MoveToFolderModal
                        folder={collection.folder}
                        isOpen={showMoveToFolderModal}
                        onClose={closeMoveToFolderModal}
                        onChange={handleOnChangeFolder}
                      />
                      <ExportModal
                        collection={collection}
                        isOpen={showExportModal}
                        onClose={closeExportModal}
                      />
                      <ModalWrapper
                        title={t('common.collection')}
                        isOpen={showCollectionWorkflowModal}
                        onClose={closeCollectionWorkflowModal}
                      >
                        <ChangeWorkflowForm
                          defaultValues={collection}
                          isOpen={showEditCollectionModal}
                          onSubmit={async data => {
                            await handleOnUpdateCollection(data);
                            closeCollectionWorkflowModal();
                          }}
                        />
                      </ModalWrapper>
                      <ConfirmationModal
                        deleteText={t(
                          'confirmation.all_information_in_this_collection_will_be_permanently_removed_and_cannot_be_restored'
                        )}
                        isOpen={showDeleteCollectionModal}
                        onClose={closeDeleteCollectionModal}
                        onDelete={handleOnDeleteCollection}
                      />
                    </>
                  )}
                </>
              </Container>
            </Flex>
          </SimpleGrid>
        )}
      </LoadingWrapper>
    </>
  );
};

export default CollectionDetailed;
