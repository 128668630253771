import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Box, HStack, Stack, Text, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/pro-regular-svg-icons';

const AccessBadge = ({ field }) => {
  const { t } = useTranslation();

  const getAccessLabelLong = () => {
    let list = [
      t('common.contributors'),
      t('common.assignees'),
      t('common.collection_managers'),
      t('common.managers'),
      t('common.admins'),
      t('common.panel')
    ];
    if (field?.scorer === 'EVE') {
      list = [t('common.everyone')];
    }
    if (field?.scorer === 'COL') {
      list = list.slice(2, list.length - 1);
    }
    if (field?.form || field?.scorer === null) {
      list = list.slice(0, list.length - 1);
    }
    return list.length > 0 ? (
      <>
        <Text>{`${t('template.field_scorer_viewable_and_editable_by')}:`}</Text>
        <Box ml={4} pb={2}>
          <ul>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Box>
      </>
    ) : null;
  };

  const accessList = getAccessLabelLong();

  return accessList?.length > 0 ? (
    <HStack spacing={2}>
      <Tooltip label={<Stack spacing={2}>{getAccessLabelLong()}</Stack>}>
        <Badge>
          <HStack>
            <HStack spacing={2}>
              <FontAwesomeIcon icon={faEye} />
              <FontAwesomeIcon icon={faEdit} />
            </HStack>
            <Text>
              {field?.form
                ? t('common.contributors')
                : field?.scorer === 'EVE'
                ? t('common.everyone')
                : field?.scorer === 'COL'
                ? t('common.collection_managers')
                : t('common.contributors')}
            </Text>
          </HStack>
        </Badge>
      </Tooltip>
    </HStack>
  ) : null;
};

export default AccessBadge;
