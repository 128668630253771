import {
  Box,
  Container,
  Flex,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAnimate, useInView } from 'framer-motion';
import { useUi } from 'providers/UiProvider';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ScrollIndicator = ({ icon, ...rest }) => {
  const theme = useTheme();
  const chevronColor = useColorModeValue(
    theme.colors.gray[500],
    theme.colors.gray[200]
  );
  return (
    <Flex
      color={chevronColor}
      fontSize="3xl"
      justifyContent="center"
      alignItems="center"
      pointerEvents="none"
      position="absolute"
      zIndex={1}
      {...rest}
    >
      <FontAwesomeIcon icon={icon} />
    </Flex>
  );
};

const ScrollableContainer = ({
  direction = 'vertical',
  maxHeight,
  maxWidth,
  children,
  isEmpty = false,
  ...rest
}) => {
  const [progressX, setProgressX] = useState(0);
  const [progressY, setProgressY] = useState(0);

  const { t } = useTranslation();

  const onContainerScroll = e => {
    setProgressX(
      e.target?.scrollLeft / (e.target?.scrollWidth - e.target.clientWidth)
    );
    setProgressY(
      e.target?.scrollTop / (e.target?.scrollHeight - e.target.clientHeight)
    );
    animate(
      scope.current,
      {
        opacity: 0
      },
      { duration: 0.0001 }
    );
  };

  const containerRef = useRef();

  const [scope, animate] = useAnimate();
  const isInView = useInView(scope);
  const { setScrollParent } = useUi();

  useEffect(() => {
    if (isInView) {
      animate(
        scope.current,
        {
          opacity: [1, 0, 1, 0, 1, 0, 1, 0]
        },
        { duration: 4, delay: 1 }
      );
    }
  }, [isInView, animate, scope]);

  useEffect(() => {
    if (containerRef.current) {
      setScrollParent(containerRef);
    }

    // Cleanup function to clear the scrollParent when the component unmounts
    return () => {
      setScrollParent(null);
    };
  }, [containerRef, setScrollParent]);

  return isEmpty ? (
    <Text textAlign="center" variant="muted">
      {t('common.nothing_here_yet')}
    </Text>
  ) : (
    <Box
      position="relative"
      rounded="lg"
      padding={0}
      height={maxHeight ? maxHeight : '66%'}
      width={maxWidth ? maxWidth : '100%'}
    >
      <Container
        ref={containerRef}
        maxHeight="full"
        maxWidth="full"
        width="full"
        className="scroll-demo"
        rounded="lg"
        onScroll={onContainerScroll}
        {...rest}
      >
        <Box
          width="full"
          px={0}
          position="relative"
          pr={direction === 'horizontal' || direction === 'both' ? 12 : null}
          pb={direction === 'vertical' || direction === 'both' ? 8 : null}
        >
          {children}
        </Box>
      </Container>
      <div position="absolute" top={0} ref={scope}>
        {(direction === 'both' || direction === 'vertical') &&
        containerRef?.current?.scrollHeight >
          containerRef?.current?.clientHeight ? (
          <ScrollIndicator
            icon={faChevronDown}
            height="1vw"
            width="full"
            left={0}
            bottom={4}
            opacity={progressY > 0.9999 ? 0 : 1}
          />
        ) : null}
        {(direction === 'both' || direction === 'horizontal') &&
        containerRef?.current?.scrollWidth >
          containerRef?.current?.clientWidth ? (
          <ScrollIndicator
            icon={faChevronRight}
            height="full"
            width="1vw"
            right={8}
            top={0}
            opacity={progressX > 0.9999 ? 0 : 1}
          />
        ) : null}
      </div>
    </Box>
  );
};

export default ScrollableContainer;
