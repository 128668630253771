import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, HStack, Show, Stack, Text } from '@chakra-ui/react';
import StepModalHeader from 'components/StepModalHeader';
import ModalWrapper from 'components/ModalWrapper';
import ScoreForm from 'features/card/ScoreForm';
import MultiSelectForm from 'features/card/MultiSelectForm';
import InputForm from 'features/card/InputForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-solid-svg-icons';
import ScrollableContainer from 'components/ScrollableContainer';

const NavButtons = ({ formState, currentStepIndex, handleOnClickNav }) => {
  const { t } = useTranslation();
  return (
    <HStack spacing={8} justifyContent="center" width="full" pb={4}>
      <HStack width="container.sm" justifyContent="space-between">
        <Button
          variant="outline"
          isDisabled={currentStepIndex === 0}
          onClick={() => {
            handleOnClickNav(-1);
          }}
          width="16ch"
        >
          <HStack>
            <FontAwesomeIcon icon={faChevronLeft} />
            <Show above="sm">
              <Text>{t('button.previous')}</Text>
            </Show>
          </HStack>
        </Button>
        <Button
          type="submit"
          variant="outline"
          isDisabled={formState.isDisabled}
          isLoading={formState.isLoading}
          onClick={() => {
            handleOnClickNav(1);
          }}
          width="16ch"
        >
          <HStack>
            <Show above="sm">
              <Text>{t('button.next')}</Text>
            </Show>
            <FontAwesomeIcon icon={faChevronRight} />
          </HStack>
        </Button>
      </HStack>
    </HStack>
  );
};

const SurveyStepModal = ({ survey, isOpen, onClose, isMobile = false }) => {
  const fields = survey?.workflow?.fields;

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [formState, setFormState] = useState({
    isDisabled: false,
    isLoading: false
  });
  const [scrollIndicatorKey, setScrollIndicatorKey] = useState(Math.random());

  const { t } = useTranslation();

  const lastStepIndex = fields.length - 1;

  const handleOnFormStateUpdate = state => {
    if (state) {
      if (
        state.isSubmitting === formState.isSubmitting &&
        state.isValid === formState.isValid
      ) {
        return;
      } else {
        setFormState({ ...state });
      }
    }
  };

  const handleOnClickNav = direction => {
    if (direction === 1) {
      console.log('SUBMIT SNEAKILY');
    }
    if (
      currentStepIndex + direction < 0 ||
      currentStepIndex + direction > fields.length - 1
    ) {
      console.log('cant do eet cuz at index: ', currentStepIndex);
    } else {
      setCurrentStepIndex(currentStepIndex + direction);
    }
    setScrollIndicatorKey(Math.random());
  };

  const renderField = field => {
    if (field.type === 'INPUT') {
      // const answer = findAnswer(field, card.answers);
      // if (forReport && !answer) {
      //   return null;
      // }
      return (
        <InputForm
          //onSubmit={handleOnClickNext}
          isOpen
          //defaultValue={selectedAnswer?.answer}
          helpText={field?.help_text}
          handleOnFormStateUpdate={handleOnFormStateUpdate}
          withoutSubmitButtons
        />
      );
    } else if (field.type === 'MULTI_SELECT') {
      //const answer = findAnswer(field, card.selections);
      // TODO: FIX!
      // if (!answer) {
      //   return null;
      // }
      return (
        <MultiSelectForm
          answers={[]}
          //answers={findAnswer(selectedField, card.selections)}
          options={field.options}
          helpText={field?.help_text}
          handleOnFormStateUpdate={handleOnFormStateUpdate}
          withoutSubmitButtons
        />
      );
    } else if (field.type === 'RATING' && field.chart) {
      return (
        <ScoreForm
          defaultValues={null}
          // defaultValues={scored ? { score: scored } : null}
          chart={field.chart}
          isOpen
          onDelete={() => {
            console.error('onDelete missing');
          }}
          handleOnFormStateUpdate={handleOnFormStateUpdate} //TODO: handle scored correctly!!!
          withoutSubmitButtons
          //onSubmit={scored ? handleOnUpdateScore : handleOnCreateScore}
        />
      );
    }
    return null;
  };

  return (
    <ModalWrapper
      size="full"
      isOpen={isOpen}
      onClose={onClose}
      hasSteps
      //expanding ModalWrapper for this after all, to allow surveys to benefit from confirmClosure in the near future! :)
      footerContent={
        <NavButtons
          formState={formState}
          currentStepIndex={currentStepIndex}
          handleOnClickNav={handleOnClickNav}
        />
      }
    >
      <Stack spacing={12} mt={-8}>
        <StepModalHeader
          currentTitle={t('survey.question_number_of_total', {
            number: currentStepIndex + 1,
            total: lastStepIndex + 1
          })}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          lastStepIndex={lastStepIndex}
          onClose={onClose}
          showBackButton={false}
          useProgressBar
        />
        <Stack spacing={8}>
          <Text fontSize="lg" fontWeight="bold">
            {fields[currentStepIndex].field}
          </Text>
          <ScrollableContainer maxHeight="50vh" key={scrollIndicatorKey}>
            {renderField(fields[currentStepIndex])}
          </ScrollableContainer>
        </Stack>
      </Stack>
    </ModalWrapper>
  );
};

export default SurveyStepModal;
