import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import NewItem from 'components/NewItem';
import CollectionCreationForm from 'features/collection/CollectionCreationForm';
import CollectionListItem from 'features/collection/CollectionListItem';
import api from 'utils/api';

const FolderCollections = ({ folder }) => {
  const { slug } = useParams();

  const { t } = useTranslation();

  const {
    isOpen: showCreateCollectionModal,
    onOpen: openCreateCollectionModal,
    onClose: closeCreateCollectionModal
  } = useDisclosure();

  const queryClient = useQueryClient();

  const {
    status,
    data: collections,
    isSuccess,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(
    ['folder', folder.id, 'collections'],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        folder: folder.id,
        offset: pageParam,
        limit: 20
      });
      params.append('hive__slug', slug);
      const { data } = await api.get(`/collections?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  return (
    <Box>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.collections').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <>
            <InfiniteScrollHelper
              hasMore={!isFetching && hasNextPage}
              loadMore={fetchNextPage}
            >
              <SimpleGrid columns={[1, 2]} spacing={6}>
                <NewItem onClick={openCreateCollectionModal} />
                {collections.pages[0].count > 0 &&
                  collections.pages.map(page =>
                    page.results.map(collection => (
                      <CollectionListItem
                        key={collection.id}
                        collection={collection}
                      />
                    ))
                  )}
              </SimpleGrid>
              {isFetching && <LoadingIndicator />}
            </InfiniteScrollHelper>
            <ModalWrapper
              size="full"
              isOpen={showCreateCollectionModal}
              onClose={closeCreateCollectionModal}
              hasSteps
            >
              <CollectionCreationForm
                isOpen={showCreateCollectionModal}
                onClose={closeCreateCollectionModal}
                folder={folder}
                onCollectionCreated={newCollection => {
                  if (collections && collections.pages) {
                    const pages = collections.pages.map(page => {
                      return {
                        ...page,
                        count: page.count + 1
                      };
                    });
                    if (pages.length > 0) {
                      pages[0].results = [newCollection, ...pages[0].results];
                    }
                    queryClient.setQueryData(
                      ['folder', folder.id, 'collections'],
                      {
                        ...collections,
                        pages
                      }
                    );
                  }
                }}
                newCollection={null}
              />
            </ModalWrapper>
          </>
        ) : null}
      </LoadingWrapper>
    </Box>
  );
};

export default FolderCollections;
