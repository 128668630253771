import LanguageDetect from 'languagedetect';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import {
  Button,
  Box,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import TextareaForm from 'components/TextareaForm';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import CommentListItem from './CommentListItem';
import api from 'utils/api';

const detector = new LanguageDetect();

const CommentList = ({
  cardId,
  code,
  limit = null,
  onDeleteComment,
  onUpdateComment,
  onCreateReply,
  handleOnSetSelectedComment,
  selectedComment,
  isAdminOrAssigneeOrCollectionManager
}) => {
  const {
    isOpen: showReplyCommentModal,
    onOpen: openReplyCommentModal,
    onClose: closeReplyCommentModal
  } = useDisclosure();

  const {
    isOpen: showDeleteCommentModal,
    onOpen: openDeleteCommentModal,
    onClose: closeDeleteCommentModal
  } = useDisclosure();

  const {
    isOpen: showUpdateCommentModal,
    onOpen: openUpdateCommentModal,
    onClose: closeUpdateCommentModal
  } = useDisclosure();

  const { t } = useTranslation();

  const {
    status,
    data: comments,
    isSuccess,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(
    ['comments', cardId],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams();
      params.append('card', cardId);
      params.append('offset', pageParam);
      params.append('limit', limit ? limit : 10);
      params.append('parent__isnull', true);
      if (code) {
        params.append('code', code);
      }
      const { data } = await api.get(`/comments?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  return (
    <Stack>
      <LoadingWrapper
        indicator={
          <HStack width="70%" alignItems="flex-start">
            <SkeletonCircle />
            <Stack width="50%">
              <HStack width="60%">
                <Skeleton height="15px" width="70%" />
                <Skeleton height="15px" width="30%" />
              </HStack>
              <SkeletonText width="100%" noOfLines={2} />
            </Stack>
          </HStack>
        }
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.comments').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={8}>
            {comments?.pages?.map((page, pageIndex) => {
              if (limit === null || pageIndex < 1) {
                return page.results.map((comment, commentIndex) => {
                  if (limit === null || commentIndex < limit) {
                    return (
                      <CommentListItem
                        key={comment.id}
                        code={code}
                        comment={comment}
                        detector={detector}
                        onClickDelete={comment => {
                          handleOnSetSelectedComment(comment);
                          openDeleteCommentModal();
                        }}
                        onClickUpdate={comment => {
                          handleOnSetSelectedComment(comment);
                          openUpdateCommentModal();
                        }}
                        onClickReply={comment => {
                          handleOnSetSelectedComment(comment);
                          openReplyCommentModal();
                        }}
                        isAdminOrAssigneeOrCollectionManager={
                          isAdminOrAssigneeOrCollectionManager
                        }
                        isParent
                      />
                    );
                  }
                  return [];
                });
              }
              return [];
            })}
            {hasNextPage && !!!limit && (
              <Box>
                <Button size="sm" variant="link" onClick={fetchNextPage}>
                  {t('button.load_more_comments')}
                </Button>
              </Box>
            )}
          </Stack>
        ) : null}
      </LoadingWrapper>
      {showDeleteCommentModal && (
        <ConfirmationModal
          deleteText={t(
            'confirmation.this_comment_will_be_permanently_removed_and_cannot_be_restored'
          )}
          isOpen={showDeleteCommentModal}
          onClose={closeDeleteCommentModal}
          onDelete={comment => {
            onDeleteComment(comment, closeDeleteCommentModal);
          }}
        />
      )}
      {showUpdateCommentModal && (
        <ModalWrapper
          title={t('common.comment')}
          isOpen={showUpdateCommentModal}
          onClose={closeUpdateCommentModal}
        >
          <TextareaForm
            onSubmit={async comment => {
              await onUpdateComment(comment, closeUpdateCommentModal);
            }}
            isOpen={showUpdateCommentModal}
            defaultValue={selectedComment?.comment.trim()}
          />
        </ModalWrapper>
      )}
      <ModalWrapper
        title={t('common.reply_verb')}
        isOpen={showReplyCommentModal}
        onClose={closeReplyCommentModal}
      >
        <TextareaForm
          onSubmit={async reply => {
            await onCreateReply(reply, closeReplyCommentModal);
          }}
          isOpen={showReplyCommentModal}
        />
      </ModalWrapper>
    </Stack>
  );
};

export default CommentList;
