import axios from 'axios';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  Heading,
  Input,
  Progress,
  SimpleGrid,
  Stack,
  useToast
} from '@chakra-ui/react';
import NewItem from 'components/NewItem';
import ModalWrapper from 'components/ModalWrapper';
import AttachmentList from 'features/attachment/AttachmentList';
import api from 'utils/api';
import { sleepMs } from 'utils/network';

const FolderAttachments = ({ folderId }) => {
  const attachmentRef = useRef();

  const toast = useToast();
  const { t } = useTranslation();

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const queryClient = useQueryClient();

  const handleOnUploadAttachment = async file => {
    if (file.size > 1000000000) {
      toast({
        title: 'File is too large. File cannot be larger than 1 GB.',
        status: 'error',
        position: 'bottom-right',
        isClosable: true
      });
    } else {
      setProgress(0);
      setUploading(true);
      await sleepMs(250);
      try {
        const { data } = await api.post('/s3/generate-presigned-url', {
          filename: file.name
        });
        await axios.put(data.url, file, {
          headers: { 'x-amz-acl': 'public-read' },
          onUploadProgress: ({ loaded, total }) => {
            const percentage = Math.floor((loaded * 100) / total);
            setProgress(percentage);
          }
        });
        const { data: attachment } = await api.post(`/folders/attachments`, {
          key: data.key,
          name: file.name,
          folder: folderId
        });
        const attachments = queryClient.getQueryData([
          'attachments',
          'folders',
          folderId
        ]);
        if (attachments) {
          queryClient.setQueryData(
            ['attachments', 'folders', folderId],
            [...attachments, attachment]
          );
        }
      } catch (e) {
        toast({
          title: 'Could not upload attachment. Please try again later.',
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
        console.error(e);
      }
      await sleepMs(250);
      setUploading(false);
    }
  };

  return (
    <Stack spacing={4}>
      <Heading fontSize="lg">{t('common.attachments')}</Heading>
      <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing={8}>
        <NewItem
          onClick={() => {
            if (attachmentRef?.current) {
              attachmentRef.current.click();
            }
          }}
        />
        <AttachmentList
          modelConfig={{
            singular: 'folder',
            plural: 'folders',
            modelId: folderId
          }}
          canEdit
        />
      </SimpleGrid>
      <Input
        ref={attachmentRef}
        type="file"
        display="none"
        onChange={event => {
          handleOnUploadAttachment(event.target.files[0]);
        }}
      />
      <ModalWrapper
        title={t('common.uploading_attachment')}
        isOpen={uploading}
        pb={6}
      >
        <Progress colorScheme="teal" hasStripe value={progress} rounded="lg" />
      </ModalWrapper>
    </Stack>
  );
};

export default FolderAttachments;
